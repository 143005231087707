import { QueryStringProvider } from '@kaliber/use-query-string'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { TechblogListAndFilter } from '/features/pageOnly/techblog/TechblogListAndFilter'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { Loader } from '/features/buildingBlocks/Loader'

import styles from './TechblogListAndFilterApp.css'

const client = new QueryClient()

export default function TechblogListAndFilterApp({
  initialTechblogs,
  totalTechblogsCount,
  filters,
  search,
}) {
  const isMounted = useRenderOnMount()

  if (!isMounted) return (
    <div data-style-context='light' className={styles.component_root}>
      <Loader layoutClassName={styles.loaderLayout} />
    </div>
  )

  return (
    <QueryStringProvider {...{ search }}>
      <QueryClientProvider {...{ client }}>
        <TechblogListAndFilter {...{ initialTechblogs, totalTechblogsCount, filters }} />
      </QueryClientProvider>
    </QueryStringProvider>
  )
}
