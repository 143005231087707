import { ContainerLg } from '/features/buildingBlocks/Container'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useQueryString } from '@kaliber/use-query-string'
import { useTechblogFiltersWithValues } from '/machinery/useFiltersWithValues'
import { useNormalizedTechblogFilterValues } from '/machinery/useNormalizedFilterValues'
import { OverviewFilters } from '/features/buildingBlocks/OverviewFilters'
import { MobileFilters } from '/features/buildingBlocks/MobileFilters'
import { TechblogResults } from '/features/pageOnly/techblog/TechblogResults'
import { useMediaQuery } from '@kaliber/use-media-query'
import { Pagination } from '/features/buildingBlocks/Pagination'
import { useReportError } from '/machinery/ReportError'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { fetchWithResponseHandler } from '/machinery/fetchWithResponseHandler'
import { TECHBLOGS_PER_PAGE, routeMap } from '/routeMap'
import { NoResultsCard } from '/features/pageOnly/jobs/NoResultsCard'
import { useTrackCurrentFiltersAndQuery } from '/analytics/user-interaction'

import mediaStyles from '/cssGlobal/media.css'
import styles from './TechblogListAndFilter.css'

export function TechblogListAndFilter({ initialTechblogs, totalTechblogsCount, filters }) {
  const language = useLanguage()
  const { __ } = useTranslate()
  const [queryString, setQueryString] = useQueryString()
  const normalizedValues = useNormalizedTechblogFilterValues(queryString)
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const scrollRef = React.useRef(null)

  const { techblogs, totalTechblogs, isError, isSuccess, isFetching } = useFetchTechblogs({
    normalizedValues, initialTechblogs, language
  })

  const filtersAndValues = useTechblogFiltersWithValues({
    filters,
    values: normalizedValues,
  })

  useTrackCurrentFiltersAndQuery(filtersAndValues, totalTechblogs, 'multi_select')

  const scrollTop = scrollRef?.current?.offsetTop

  return (
    <div className={styles.component} data-style-context='light'>
      <ContainerLg layoutClassName={styles.containerLayout}>
        <div className={styles.contentContainer}>
          {!isViewportMd && (
            <MobileFilters
              totalCount={totalTechblogsCount}
              {...{ handleFilterChange, handleResetClick, filtersAndValues }}
            />
          )}

          {isViewportMd && (
            <div className={styles.filters}>
              <h4 className={styles.filtersHeader}>{__`filters-title`}</h4>
              <div className={styles.filtersContainer}>
                <OverviewFilters
                  onReset={handleResetClick}
                  onFilterChange={handleFilterChange}
                  {...{ filtersAndValues }}
                />
              </div>
            </div>
          )}

          <div className={styles.results} ref={scrollRef}>
            <h4 className={styles.resultsHeader}>
              {totalTechblogs < totalTechblogsCount ? (
                <span>
                  <span className={styles.highlight}>{totalTechblogs}</span>
                  &nbsp;{__`of-the`}
                  <span className={styles.highlight}>&nbsp;{totalTechblogsCount}</span>
                  &nbsp;{__(totalTechblogsCount)`articles-found`}
                </span>
              ) : (
                <span>
                  <span className={styles.highlight}>{totalTechblogs}</span>
                  &nbsp;{__(totalTechblogs)`articles-found`}
                </span>
              )}
            </h4>

            {isSuccess && Boolean(techblogs.length)
              ? <TechblogResults {...{ techblogs, isError, isSuccess, isFetching }} />
              : (
                <NoResultsCard
                  title={__`no-articles-found`}
                  dataX='clear-techblog-filters'
                  onResetClick={handleAllFiltersResetClick}
                />
              )}

            {totalTechblogs >= 1 && (
              <div className={styles.pagination}>
                <Pagination
                  currentPage={normalizedValues.page}
                  maxPages={Math.ceil(totalTechblogs / TECHBLOGS_PER_PAGE)}
                  onPageChange={handleOnPageChange}
                />
              </div>
            )}
          </div>
        </div>
      </ContainerLg>
    </div>
  )

  function handleOnPageChange(page) {
    window.scrollTo({ top: scrollTop, behavior: 'smooth' })
    setQueryString(x => ({ ...x, page }))
  }

  function handleAllFiltersResetClick() {
    setQueryString({})
  }

  function handleResetClick() {
    setQueryString(x => ({ category: x.category }))
  }

  function handleFilterChange(filterChanges) {
    setQueryString(x => ({ ...x, ...filterChanges, page: null }))
  }
}

function useFetchTechblogs({ normalizedValues, initialTechblogs, language }) {
  const reportError = useReportError()

  const { data, isError, isSuccess, isFetching } = useQuery({
    queryKey: ['techblogs', normalizedValues],
    initialData: initialTechblogs,
    queryFn: () => fetchTechblogs({ values: normalizedValues, language, reportError }),
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    retry: 3,
  })

  const totalTechblogs = data?.hits?.total?.value ?? 0
  const techblogs = data?.hits?.hits ?? []

  return { techblogs, totalTechblogs, isError, isSuccess, isFetching }
}

async function fetchTechblogs({ values, language, reportError }) {
  const {
    page,
    category,
    tag,
  } = values

  try {
    return fetchWithResponseHandler(routeMap.api.v1.techblogs(), {
      method: 'POST',
      body: JSON.stringify({
        filters: {
          category,
          tag,
        },
        language,
        page
      })
    })
  } catch (e) {
    reportError(e)
  }
}
