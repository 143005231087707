import { routeMap } from '/routeMap'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { dayjs } from '/machinery/dayjs'

import { Card } from '/features/buildingBlocks/card/Card'
import { Labels } from '/features/buildingBlocks/Labels'
import ImageCover from '/features/buildingBlocks/ImageCover.universal'

import styles from './TechblogResults.css'

export function TechblogResults({ techblogs, isSuccess, isFetching }) {
  const language = useLanguage()

  return (
    <ul className={cx(styles.component, isFetching && styles.isLoading)}>
      {techblogs?.map(({ _source: techblog }, i) => {
        const {
          id,
          slug,
          category: categories,
          title,
          employees,
          readingTime,
          publishDate
        } = techblog
        const date = publishDate * 1000

        return (
          <li key={`${id}__${i}`}>
            <Card href={routeMap.app.techblog.detail({ language, slug: slug.current })} dataX='link-to-techblog' dataXContext={id}>
              <TechblogCard {...{ categories, title, employees, readingTime, date }} />
            </Card>
          </li>
        )
      })}
    </ul>
  )
}

function TechblogCard({ categories: categoriesRaw, title, employees, readingTime, date }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  const categories = categoriesRaw?.length > 0
    ? categoriesRaw.map(x => x?.[`label_${language}`] ?? x?.label_en ?? null).filter(Boolean)
    : []

  const labels = [
    ...(date) ? [{ title: dayjs(date).locale(language).format('LL') }] : [],
    ...(readingTime) ? [{ title: __(Number(readingTime), { count: readingTime })`article-reading-time` }] : [],
  ]

  return (
    <div className={styles.componentTechblogCard}>
      {Boolean(categories.length) && <Categories layoutClassName={styles.categoriesLayout} {...{ categories }} />}
      <h4 className={styles.title}>{title}</h4>
      <div className={styles.metadata}>
        <Authors layoutClassName={styles.authorsLayout} {...{ employees }} />
        <span className={styles.seperator} />
        <Labels {...{ labels }} />
      </div>
    </div>
  )
}

function Categories({ layoutClassName, categories }) {
  return (
    <ul className={cx(styles.componentCategories, layoutClassName)}>
      {categories.map((category, i) => (
        <li key={i} className={styles.category}>
          <span>{category}</span>
        </li>
      ))}
    </ul>
  )
}

function Authors({ employees, layoutClassName }) {
  const employeesWithAvatar = employees?.filter(x => x.avatar) || []
  const employeeTitles = employees?.filter(x => x.title) || []

  return (
    <div className={cx(styles.componentAuthors, layoutClassName)}>
      {Boolean(employeesWithAvatar.length) && (
        <div className={styles.avatars}>
          {employeesWithAvatar.map((x, i) => (
            <div key={`image__${x._key}__${i}`} className={styles.avatar}>
              <ImageCover aspectRatio={1 / 1} image={x.avatar} />
            </div>
          ))}
        </div>
      )}
      {Boolean(employeeTitles.length) && (
        <div className={styles.titles}>
          {employeeTitles.map((x, i) => (
            <React.Fragment key={`employee__${x._key}__${i}`}>{i > 0 && ' & '}<span>{x.title}</span></React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}
